<template>
  <div class="mx-4 my-4 md:mx-8 md:my-8">
    <h1 class="text-xl mb-3">
        <b>Empresa:</b> {{ empresa ? empresa.nome: '' }}
    </h1>
    <img class="h-48 w-96 mt-2 mb-2" v-if="empresa && empresa.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${empresa.logo.filename}&folder=empresas`"/>
    <hr class="mb-4"/>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-6">
            <h3 class="text-lg mb-2">
                <b>CNPJ:</b> {{ empresa ? empresa.cnpj: '' }}
            </h3>
            <h3 class="text-lg mb-2">
                <b>Razão social:</b> {{ empresa ? empresa.razaoSocial: '' }}
            </h3>
            <h3 class="text-lg mb-2">
                <b>Responsável:</b> {{ empresa ? empresa.responsavel: '' }}
            </h3>
        </div>
        <div class="col-span-12 md:col-span-6">
            <h3 class="text-lg mb-2">
                <b>Endereço:</b> {{ empresa ? empresa.endereco: '' }}
            </h3>
            <h3 class="text-lg mb-2">
                <b>Email:</b> {{ empresa ? empresa.email: '' }}
            </h3>
            <h3 class="text-lg mb-2">
                <b>Telefone:</b> {{ empresa ? empresa.telefone: '' }}
            </h3>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empresa: null,
    }
  },
  async beforeMount() {
    const id = this.$route.params.id;
    if (id) {
        const req = await this.$http.get(`/v1/empresas/${id}`);
        this.empresa = req.data;
    }
  },
}
</script>